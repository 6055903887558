import React from 'react'
import SvgBg from './bg.svg'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <div className="w-screen h-screen flex justify-center items-center text-3xl font-bold relative font-header font-500 flex-col ">
    <div className="z-10">Page not found</div>
    <Link to="/" className="btn btn-primary z-10">
      Back to home
    </Link>
    <img
      alt="not-found-background-image"
      src={SvgBg}
      className="absolute top-0 left-0 right-0 bottom-0 min-h-screen object-cover"
    />
  </div>
)

export default NotFoundPage
